import React from 'react'
import '../css/OurServices.css';

import kaliLogo from '../img/services/kali-linux-logo.jpg';
import nodeLogo from '../img/services/nodejs-logo.png'
import rocketChatLogo from '../img/services/rocketchat-logo.jpg'
const OurServices = () => {
    return (
        <div className="consulting-container">
            <h1>Our Open Source Consulting Services</h1>
            <p>We provide a wide range of OSS (Open Source Software) consulting services tailored to meet your business needs.</p>

            <div className="services-list">
                <div className="service-item">
                    <h3>Penetration Testing</h3>
                    <img src={kaliLogo} alt="Kali linux" />
                    <p>We use Kali Linux and industry-standard tools to perform advanced penetration tests and identify vulnerabilities in your systems.</p>
                </div>
                <div className="service-item">
                    <h3>Software Development</h3>
                    <img src={nodeLogo} alt="Node.js" />
                    <p>We provide individualized software solutions with Node.js, React and so much more...</p>
                </div>
                <div className="service-item">
                    <h3>Self-Hosting rocket.chat for internal communications</h3>
                    <img src={rocketChatLogo} alt="Rocket.chat" />
                    <p>We will show you how you can replace microsoft Teams or Slack with your own E2E encrypted chat, at a much lower cost.</p>
                </div>
            </div>

            <a href="/contact" className="consulting-btn">Get a Free Consultation</a>
        </div>
    );
};

export default OurServices;