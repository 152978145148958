import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './css/App.css'; // Import the CSS file
import Home from './js/Home'; // Import Home component
import Contact from './js/Contact'; // Import Contact component
import OurTeam from './js/OurTeam';
import OurServices from "./js/OurServices";

import React, {useEffect, useState} from 'react';
import enMessages from '../src/locales/en/translation.json';
import heMessages from '../src/locales/he/translation.json';
import arMessages from '../src/locales/ar/translation.json';
import deMessages from '../src/locales/de/translation.json';
import svMessages from '../src/locales/sv/translation.json';
import '@fortawesome/fontawesome-free/css/all.min.css';

function App() {
  const [language, setLanguage] = useState('en'); // Default language
  const [menuOpen, setMenuOpen] = useState(false); // State to toggle menu visibility
  const toggleMenu = () => {
    setMenuOpen(!menuOpen); // Toggle menu visibility
  };
  const messagesMap = {
    en: enMessages,
    he: heMessages,
    ar: arMessages,
    de: deMessages,
    sv: svMessages
  };

  const messages = messagesMap[language];

  useEffect(() => {
    document.body.setAttribute('dir', language === 'ar' || language === 'he' ? 'rtl' : 'ltr');
  }, [language]);

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  return (
      <Router>
        <div className="App">
          {/* Header */}
          <header className="App-header">
            <Link to="/" className="logo">
              <h1>SQ</h1>
            </Link>
            {/* Hamburger Icon */}
            <div className="hamburger" onClick={toggleMenu}>
              <i className="fas fa-bars"></i>
            </div>

            <nav className={`nav-links ${menuOpen ? 'active' : ''}`}>
              <Link to="/" onClick={toggleMenu}>{messages.home}</Link>
              <Link to="/contact" onClick={toggleMenu}>{messages.contact}</Link>
              <Link to="/ourteam" onClick={toggleMenu}>{messages.our_team}</Link>
              <Link to="/ourservices" onClick={toggleMenu}>Our Services</Link>
              <div className="language-switcher">
                <select value={language} onChange={handleLanguageChange}>
                  <option value="en">English</option>
                  <option value="he">עברית</option>
                  <option value="ar">العربية</option>
                  <option value="de">Deutsch</option>
                  <option value="sv">Svenska</option>
                </select>
              </div>
            </nav>


          </header>

          {/* Main Content */}
          <main className="App-main">


            {/* Define Route for Home */}
            <Routes>
              <Route path="/" element={<Home messages={messages}/>}/>
              <Route path="/contact" element={<Contact messages={messages}/>}/>
              <Route path="/ourteam" element={<OurTeam messages={messages}/>}/>
              <Route path="/ourservices" element={<OurServices />} />
            </Routes>
          </main>

          {/* Footer */}
          <footer className="App-footer">
            <p>&copy; 2024 SQ. All rights reserved</p>
            <div className="company-address">
              <p>1234 Modern Web Ave, Suite 100</p>
              <p>San Francisco, CA 94107</p>
              <p>Phone: (123) 456-7890</p>
              <p>Email: info@modernweb.com</p>
            </div>
            <div className="social-media">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="social-link">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="social-link">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="social-link">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="social-link">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>

          </footer>
        </div>
      </Router>
  );
}

export default App;
