// OurTeam.js
import React from 'react';
import '../css/OurTeam.css'; // Import the CSS file
import consultant1 from '../img/consultant1.jpg'; // Adjust path as needed
import johannesCV from '../img/JohannesCVCropped.jpg';

function OurTeam({messages}) {
    return (
        <div className="our-team">
            <h1>SQ Consultants</h1>
            <div className="our-team-container">
                <ul className="team-list">
                    <li>{messages.our_team1.item1}</li>
                    <li>{messages.our_team1.item2}</li>
                    <li>{messages.our_team1.item3}</li>
                    <li>{messages.our_team1.item4}</li>
                </ul>
            </div>
            <section className="consultants">
                <h2>Meet Our Consultants</h2>
                <div className="consultant">

                    <img src={johannesCV} alt="JohannesCVCropped" className="consultant-photo" />

                    <div className="consultant-info">
                        <h3>Silvennoinen Johannes:
                        </h3>
                        <p>I have always been a tech enthusiast, I build my own computers, I host my own
                            streaming-service,
                            VPN etc. When I got started in consulting at Sec-Xtreme I instantly fell in love with it,
                            seeing
                            how such business operates I found myself thinking I could do it way better.
                        </p>
                    </div>
                </div>
                <div className="consultant">
                    <img src={consultant1} alt="Consultant 2" className="consultant-photo"/>
                    <div className="consultant-info">
                        <h3>Alaa Qasem</h3>
                        <p></p>
                    </div>
                </div>
            </section>

        </div>

    );
}

export default OurTeam;
