// Home.js
import React from 'react';
import '../css/Home.css';

function Home({ messages }) {

    return (
        <div className="home">

            <h1>{messages.consulting_plan.header}</h1>

            <section className="consulting-plan">
                <p>{messages.consulting_plan.paragraph1}</p>
                <p>{messages.consulting_plan.paragraph2}</p>
                <p>{messages.consulting_plan.paragraph3}</p>
            </section>

        </div>
    );
}

export default Home;
