// Contact.js
import React, { useState } from 'react';
import '../css/Contact.css'; // Import Contact.css

function Contact({ messages }) {
    const [formData, setFormData] = useState({ firstName: '', lastName: '', email: '', message: '' });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Sending form data to the server
        fetch('/api/form-submit', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('Success:', data);
                // Optionally handle the response from the server
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    if (!messages) {
        return <p>Loading...</p>;
    }

    return (
        <div className="contact-container">
            <h2>{messages.contact_us}</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="firstName">{messages.first_name}</label>
                    <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="lastName">{messages.last_name}</label>
                    <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">{messages.email}</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="company">{messages.company}</label>
                    <input
                        id="company"
                        name="company"
                        value={formData.company}
                        onChange={handleChange}
                    ></input>
                </div>
                <div className="form-group">
                    <label htmlFor="message">{messages.message}</label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                    ></textarea>
                </div>

                <button type="submit">{messages.submit_button || "Submit"}</button>

            </form>
        </div>
    );
}

export default Contact;
